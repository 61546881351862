import React from "react"

import { container } from "../utils/styles"

const Container = ({ children, ...rest }) => (
  <div
    css={theme => [
      container(theme),
      {
        "> div": {
          width: "100%",
        },
      },
    ]}
    {...rest}
  >
    <div>{children}</div>
  </div>
)

export default Container
